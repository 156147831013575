/* eslint-disable jsx-a11y/scope */
import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import axios from "axios";
// import Loader from "../../../public/loader.svg"
import Loader from "../../loader.svg";
import "./Admin.css";
import AdminLayout from "../../components/adminLayout";

const Viewuser = () => {
  const [storeData, setStoreData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const config = {
      method: "get",
      url: "https://blackberry-9ab311620e64.herokuapp.com/user/getusers", // Replace with your API URL
    };

    axios
      .request(config)
      .then((response) => {
        console.log("response.data", response.data);
        setStoreData(response.data.data);
        setLoading(false);
        // You can update your component's state with the response data here if needed.
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <AdminLayout>
      <h1 className="text-center">User List</h1>
      {loading === true ? (
        <>
          <div className="createloader">
            <img src={Loader} alt="loader" />
          </div>
          <div className="createloaderOverlay"></div>
        </>
      ) : (
        <div className="p-4 mt-0 mb-5 border rounded w-100 d-flex justify-content-center flex-column mx-auto">
          <table class="table table-responsive">
            <thead>
              <tr>
                <th>#</th>
                <th>User Name</th>
                <th>Store Code</th>
                <th>Store Name</th>
                <th>Store Phone</th>
                <th>Store Address</th>
                <th>User Login Email</th>
                <th>Created At</th>
              </tr>
            </thead>
            <tbody>
              {storeData &&
                storeData.map((item, index) => {
                  return (
                    <tr>
                      <td scope="row">{index + 1}</td>
                      <td scope="row">{item?.name}</td>
                      <td scope="row">{item?.storeCode}</td>
                      <td scope="row">{item?.storeName}</td>
                      <td scope="row">{item?.phone}</td>
                      <td scope="row">{item?.storeAddress}</td>
                      <td scope="row">{item?.email}</td>
                      <td scope="row">
                        <p style={{minWidth:'6rem'}}>{item?.createdAt?.split("T")[0]}</p>
                        {/* <p>{item?.createdAt?.split("T")[1]?.slice(0, 8)}</p> */}
                      </td>
                    </tr>
                  );
                })}
            </tbody>
          </table>
        </div>
      )}
    </AdminLayout>
  );
};

export default Viewuser;
