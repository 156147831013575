/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useCallback, useRef } from "react";
import axios from "axios";
import "./createCatalog.css";
import "react-toastify/dist/ReactToastify.css";
import PDFComponent from "./PDFComponent";
import Select from "react-select";

import Layout from "../../components/layout";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import SearchableSelect from "../../components/searchableSelect";
import Advanceloader from "../../advanceloader.gif";

const CreateCatalog = () => {
  const [Input, setInput] = useState("");
  const [Data, setData] = useState("");
  const [checkedItems, setCheckedItems] = useState([]);
  const [SeletedItems, setSeletedItems] = useState([]);
  const [loading, setloading] = useState(false);
  const [searchData, setsearchData] = useState([]);
  const [combinedData, setcombinedData] = useState([]);
  const [viewProducts, setviewProducts] = useState(false);
  const [viewlive, setviewlive] = useState(false);
  const [generatePdfPopup, setgeneratePdfPopup] = useState(false);
  const [filterValue, setFilterValue] = useState("");
  const [storeData, setStoreData] = useState("");
  const [selectedStore, setSelectedStore] = useState(null);
  const [storeManagerPhoneInput, setstoreManagerPhoneInput] = useState("");
  const [storeManagerInput, setstoreManagerInput] = useState("");
  const [selectedValue, setSelectedValue] = useState("Select Store"); // Initialize as 'Select Store'
  const [selectedStoreOption, setSelectedStoreOption] = useState(null);
  const inputRef = useRef(null);
  const handleSubmit = (event) => {
    event.preventDefault();
    // console.log("event", Input);
    setInput("");
  };

  const fetchUserList = async () => {
    setloading(true);
    let rowdata = {
      shop: "theblackberrys",
    };
    try {
      const options = {
        method: "get",
        // url: "https://blackberrys-catalog-api.onrender.com/product/getproducts6",
        // url: "https://blackberrys-catalog-api.onrender.com/product/getproducts8",
        // url: "https://blackberrys-catalog-api.onrender.com/product/getproducts5",
        url: "https://blackberry-9ab311620e64.herokuapp.com/product/getproducts8",
        headers: {
          "Content-Type": "application/json",
        },
        body: rowdata,
      };

      const response = await axios(options);
      if (response && response.data) {
        // console.log("Data 52", response.data);
        setData(response?.data?.products);
        setloading(false);
      }
    } catch (error) {
      console.log("Error", error);
      setloading(false);
    }
  };

  useEffect(() => {
    fetchUserList();
  }, []);

  const handleCheckboxChange = (itemId) => {
    if (checkedItems.includes(itemId)) {
      setCheckedItems(checkedItems.filter((id) => id !== itemId));
      // console.log("Checked", checkedItems);
    } else {
      setCheckedItems([...checkedItems, itemId]);
      // console.log("Checked 47", checkedItems);
    }
  };

  var jsonData =
    Data && Data?.filter((product) => checkedItems.includes(product.id));

  useEffect(() => {
    setSeletedItems(jsonData);
  }, [jsonData]);

  const handleSearch = async (event) => {
    const value = event.target.value;
    setInput(value);
    // console.log("handleSearch.value", value);
    // const data = Data && Data?.filter((product) => value.includes(product.id));
    // const data = Data && Data.filter((product) =>
    //   product.variants.some((variant) => variant.sku === value)
    // );
    const filteredData = [];
    await Data.filter((product) => {
      const matchedVariant = product.variants.find(
        (variant) => variant.sku === value
      );
      if (matchedVariant) {
        filteredData.push({ ...product, variants: [matchedVariant] });
        return true;
      }
      return false;
    });
    // console.log("object", filteredData)
    // setsearchData(data);
    setsearchData((prevSearchData) => [...prevSearchData, ...filteredData]);
    // console.log("handleSearch.data", data);
  };

  useEffect(() => {
    if (jsonData && searchData) {
      var combinedData = [...jsonData, ...searchData];
      // console.log("combinedData", combinedData);
      setcombinedData(combinedData);
    }
  }, [jsonData, searchData]);

  const removeHandle = (itemID) => {
    // console.log("itemID", itemID);
    const updatedItems = combinedData.filter((item) => item.id !== itemID);
    // console.log("updatedItems", updatedItems);
    // setcombinedData(updatedItems);
    setsearchData(updatedItems);
  };

  var filteredProducts =
    Data &&
    Data?.filter((item) => {
      const lowerCaseFilter = filterValue.toLowerCase();
      const idMatch = item.id.toString().includes(lowerCaseFilter);
      const titleMatch = item.title.toLowerCase().includes(lowerCaseFilter);
      return idMatch || titleMatch;
    });

  const [previousSize, setPreviousSize] = useState(combinedData.length);
  useEffect(() => {
    const currentSize = combinedData.length;
    if (currentSize > previousSize) {
      toast("Product add successfully!", {
        type: "success",
        autoClose: 200,
        hideProgressBar: true,
      });
      setInput("");
    } else if (currentSize < previousSize) {
      toast("Product remove successfully!", { type: "info", autoClose: 200 });
    }
    setPreviousSize(currentSize);
  }, [combinedData, previousSize]);

  useEffect(() => {
    const config = {
      method: "get",
      url: "https://blackberry-9ab311620e64.herokuapp.com/getstores", // Replace with your API URL
    };

    axios
      .request(config)
      .then((response) => {
        setStoreData(response.data);
        // You can update your component's state with the response data here if needed.
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // };

  useEffect(() => {
    if (selectedValue === "Select Store") {
      setSelectedStore(null); // No store selected, set to null or an empty object
    } else {
      // Find the selected store from storeData
      const selectedStoreData = storeData.find(
        (item) => item.storename === selectedValue
      );
      setSelectedStore(selectedStoreData);
    }
    setstoreManagerPhoneInput(selectedStore?.store_phone);
    setstoreManagerInput(selectedStore?.managername);
  }, [selectedValue, storeData, selectedStore]);

  const handleStoreChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedValue(selectedValue);
  };

  const [currentPage, setCurrentPage] = useState(1);

  const recordsPerPage = 50;

  const lastIndex = currentPage * recordsPerPage;
  const firstIndex = lastIndex - recordsPerPage;
  const records = filteredProducts.slice(firstIndex, lastIndex);
  const npage = Math.ceil(filteredProducts.length / recordsPerPage);

  function prepage() {
    if (currentPage !== 1) {
      setCurrentPage(currentPage - 1);
    }
  }

  function changeCpage(id) {
    setCurrentPage(id);
  }

  function nextpage() {
    if (currentPage !== npage) {
      setCurrentPage(currentPage + 1);
    }
  }

  const buttonsToShow = 10;
  const startPage = Math.max(1, currentPage - Math.floor(buttonsToShow / 2));
  const endPage = Math.min(npage, startPage + buttonsToShow - 1);
  const numbers = [...Array(endPage - startPage + 1).keys()].map(
    (index) => startPage + index
  );

  let sendStoredata = {
    storename: selectedStore?.storename,
    managername: storeManagerInput,
    storePhone: storeManagerPhoneInput,
    storeAdress: selectedStore?.Address,
    StoreCode: selectedStore?.storecode,
  };
  const sizeHandle = async (size) => {
    const filteredData = [];
    await Data.filter((product) => {
      const matchedVariant = product.variants.find(
        (variant) => variant.sku === size
      );
      if (matchedVariant) {
        filteredData.push({ ...product, variants: [matchedVariant] });
        return true;
      }
      return false;
    });
    setsearchData((prevSearchData) => [...prevSearchData, ...filteredData]);
  };

  const handleStoreSelect = (option) => {
    setSelectedValue(option);
  };

  const options =
    storeData &&
    storeData?.map((item) => ({
      value: item.storename,
      label: `${item.id}- ${item.storename}`,
    }));

  const viewliveHandle = () => {
    setviewlive(true);
    if (inputRef.current) {
      inputRef.current.focus();
    }
  };
  // console.log("Store selected",options);
  return (
    <Layout>
      {/* <PDFComponent /> */}
      <ToastContainer />
      <div className="">
        {loading === true ? (
          <div className="loaderDiv">
            <div className="loaderoverlay"></div>
            <img
              src={Advanceloader}
              // src="https://cdn.shopify.com/s/files/1/0776/6360/0958/files/loader.gif?v=1688965482"
              className="loader"
            />
          </div>
        ) : (
          <>
            <div className="d-flex justify-content-between align-items-center px-1 px-md-5 py-2 border-bottom shadow flex-wrap flex-md-nowrap ActionsPdf">
              <form
                onSubmit={handleSubmit}
                className="input-group my-3 w-25 scanInput"
              >
                <input
                  autoFocus
                  value={Input}
                  className="form-control"
                  type="text"
                  placeholder="Scan Product ID"
                  onChange={handleSearch}
                  ref={inputRef}
                />
              </form>
              <div className="mx-3 w-50 liveAddedBtn">
                <button
                  className="border-0 d-flex justify-content-center align-items-center w-100"
                  onClick={viewliveHandle}
                >
                  <svg
                    width="50"
                    height="40"
                    viewBox="0 0 40 40"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle
                      cx="20"
                      cy="20"
                      fill="none"
                      r="10"
                      stroke="#383a36"
                      strokeWidth="2"
                    >
                      <animate
                        attributeName="r"
                        from="8"
                        to="20"
                        dur="1.5s"
                        begin="0s"
                        repeatCount="indefinite"
                      />
                      <animate
                        attributeName="opacity"
                        from="1"
                        to="0"
                        dur="1.5s"
                        begin="0s"
                        repeatCount="indefinite"
                      />
                    </circle>
                    <circle cx="20" cy="20" fill="red" r="10" />
                  </svg>

                  {/* <svg
                    width="30px"
                    height="30px"
                    viewBox="0 0 24 24"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <circle cx="12" cy="12" r="3.5" stroke="#222222" />
                    <path
                      d="M20.188 10.9343C20.5762 11.4056 20.7703 11.6412 20.7703 12C20.7703 12.3588 20.5762 12.5944 20.188 13.0657C18.7679 14.7899 15.6357 18 12 18C8.36427 18 5.23206 14.7899 3.81197 13.0657C3.42381 12.5944 3.22973 12.3588 3.22973 12C3.22973 11.6412 3.42381 11.4056 3.81197 10.9343C5.23206 9.21014 8.36427 6 12 6C15.6357 6 18.7679 9.21014 20.188 10.9343Z"
                      stroke="#222222"
                    />
                  </svg> */}
                  <small className="text-muted d-md-none">
                    {combinedData?.length}
                  </small>
                  <small className="text-muted d-none d-md-block">
                    Click here to view added product
                  </small>
                </button>
              </div>

              <div className="count d-none d-md-block bg-success d-flex justify-content-center align-items-center m-auto border rounded">
                <p className="m-0 p-2 text-white">
                  Count:-{combinedData?.length}
                </p>
              </div>

              {combinedData?.length === 0 ? (
                <div className="downloadDiv w-100 d-flex align-items-center justify-content-end">
                  <button className="btn w-100" disabled>
                    Generate PDF
                  </button>
                </div>
              ) : (
                <div className="my-1 my-md-3 w-50 d-flex align-items-center justify-content-end DownloadAndView">
                  {/* <button
                    className="btn btn-secondary mx-2"
                    onClick={() => setviewProducts(true)}
                  >
                    View Product
                  </button> */}

                  <button
                    className="btn btn-primary mx-2 w-100"
                    onClick={() => setgeneratePdfPopup(true)}
                  >
                    Generate PDF
                  </button>
                  {/* <PDFComponent data={combinedData} /> */}
                </div>
              )}
            </div>

            <div className="mainContainer">
              <div className="productSection">
                <div className="productList">
                  <div className="form-group has-search mb-3 w-50 d-flex m-auto GlobalSearch">
                    <input
                      type="text"
                      placeholder="Global Search...."
                      className="form-control"
                      value={filterValue}
                      onChange={(e) => setFilterValue(e.target.value)}
                    />
                  </div>
                  <table className="table table-bordered">
                    <thead>
                      <tr className="text-center">
                        <th scope="col">#</th>
                        <th scope="col">Image</th>
                        <th scope="col">Title</th>
                        <th scope="col">Product Add By Size</th>
                        {/* <th scope="col">Price</th> */}
                      </tr>
                    </thead>

                    <tbody>
                      {/* {Data?.response?.products?.map((item) => ( */}
                      {records &&
                        records?.map((item, index) => (
                          <tr key={item.id}>
                            <td>
                              {index + 1}

                              {/* <input
                                className="productTitle cursor"
                                type="checkbox"
                                id={`${item.id}`}
                                checked={checkedItems.includes(item.id)}
                                onChange={() => handleCheckboxChange(item.id)}
                              /> */}
                            </td>
                            <td width={100}>
                              <label
                                htmlFor={`${item.id}`}
                                className="productTitle cursor"
                              >
                                <img
                                  src={
                                    item.image ??
                                    "https://blackberrys.com/cdn/shop/files/logo_155x52.png"
                                  }
                                  alt=""
                                  width={100}
                                  height={100}
                                />
                              </label>
                            </td>
                            <td>
                              <label
                                htmlFor={`${item.id}`}
                                className="productTitle cursor"
                              >
                                {item.title}
                              </label>
                            </td>
                            <td>
                              <label
                                htmlFor={`${item.id}`}
                                className="productTitle cursor"
                              >
                                {/* <ol className="row">
                                  {item.variants.map((variant)=>{
                                    return(
                                      <li className="col-6">{variant.sku}</li>
                                    )
                                  })}
                                    </ol> */}
                                {/* {item?.variants[0]?.sku} */}
                                {item?.variants?.map((variant) => {
                                  return (
                                    <button
                                      className="btn btn-outline-dark m-1"
                                      onClick={(e) => sizeHandle(variant.sku)}
                                    >
                                      {variant.option1}
                                    </button>
                                  );
                                })}
                              </label>
                            </td>
                         
                           {/*   <td>
                              <label
                                htmlFor={`${item.id}`}
                                className="productTitle cursor"
                              >
                                <ol className="row">
                                  {item.variants.map((variant)=>{
                                    return(
                                      <li className="col-6">{variant?.price}</li>
                                    )
                                  })}
                                    </ol> 
                                {item?.variants[0]?.price}
                              </label>
                            </td>
                          */}
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
                <div className="neo_btn_container m-1 my-md-3">
                  <button className="product_neo_btn" onClick={prepage}>
                    Previous
                  </button>
                  {numbers.map((n, i) => {
                    return (
                      <div
                        className={`page-item ${
                          currentPage === n ? "active" : " "
                        }`}
                        key={i}
                      >
                        <button
                          className={`product_neo_btn ${
                            currentPage === n ? "active-page-button" : ""
                          }`}
                          onClick={() => changeCpage(n)}
                        >
                          {n}
                        </button>
                      </div>
                    );
                  })}
                  <button className="product_neo_btn" onClick={nextpage}>
                    Next
                  </button>
                </div>
              </div>
            </div>
          </>
        )}

        {/* {viewProducts === true ? (
          <>
            <div
              className="overlaydiv"
              onClick={() => setviewProducts(false)}
            ></div>
            <div className="seclectionproductSection p-4">
              <div className="d-flex justify-content-end">
                <button
                  type="button"
                  className="btn-close mb-3"
                  onClick={() => setviewProducts(false)}
                  aria-label="Close"
                ></button>
              </div>
              {combinedData?.length === 0 ? null : (
                <div className="row col-12 align-items-center justify-content-center viewProductsContent">
                  {combinedData?.map((item) => (
                    <div className="card m-2 col-9 col-md-3" key={item.id}>
                      <img
                        src={
                          item?.image ??
                          "https://blackberrys.com/cdn/shop/files/logo_155x52.png"
                        }
                        alt=""
                        width={200}
                        height={250}
                        className="p-2"
                      />
                      <label className="h6 my-2">{item.title}</label>
                      <label className="d-flex justify-content-between align-items-center">
                        <p>{item?.variants[0]?.price}</p>
                      </label>
                      <p>{item?.variants[0]?.sku}</p>
                    </div>
                  ))}
                </div>
              )}
            </div>
          </>
        ) : null} */}
      </div>

      {viewlive === true ? (
        <>
          <div className="viewlive p-4">
            <div className="d-flex justify-content-between align-items-center">
              <p>{combinedData.length}</p>
              <button
                type="button"
                className="btn mb-3 btn-outline-secondary"
                onClick={() => setviewlive(false)}
              >
                Close
              </button>
            </div>
            {combinedData?.length === 0 ? (
              <div className="">
                <h2>Add Product</h2>
              </div>
            ) : (
              <div className="m-0 viewliveContent">
                {combinedData?.map((item) => {
                  const compareAtPrice = item?.variants[0]?.compare_at_price;
                  const price = item?.variants[0]?.price;

                  const higherValue =
                    compareAtPrice &&
                    parseFloat(compareAtPrice) > parseFloat(price)
                      ? compareAtPrice
                      : price;
                  return (
                    <div
                      className="px-3 m-2 bg-white border-1 rounded"
                      id={`${item.id}`}
                      key={item.id}
                    >
                      <div className="d-flex d-flex justify-content-between">
                        <p className="btn m-0">{item?.variants[0]?.option1}</p>
                        <a
                          className="btn m-0"
                          onClick={() => removeHandle(item.id)}
                        >
                          X
                        </a>
                      </div>
                      <p className="d-none">{item?.variants[0]?.sku}</p>
                      <div className="d-flex justify-content-between">
                        <img
                          src={
                            item?.image ??
                            "https://blackberrys.com/cdn/shop/files/logo_155x52.png"
                          }
                          alt=""
                          width={60}
                          height={60}
                          className="p-2"
                        />
                        <label className="h6 my-2">{item.title}</label>
                      </div>
                      <div>
                        <label className="d-flex justify-content-between align-items-center">
                          {/* <p>{item?.variants[0]?.compare_at_price}</p> */}
                          {/* <p>{item?.variants[0]?.price}</p> */}
                          <p>{higherValue}</p>
                        </label>
                      </div>
                    </div>
                  );
                })}
              </div>
            )}
          </div>
        </>
      ) : null}

      {generatePdfPopup === true ? (
        <>
          <div
            className="overlaydiv"
            onClick={() => setgeneratePdfPopup(false)}
          ></div>
          <div className="seclectionproductSection p-4 border rounded w-50">
            <div className="d-flex justify-content-end">
              <button
                type="button"
                className="btn-close mb-3"
                onClick={() => setgeneratePdfPopup(false)}
                aria-label="Close"
              ></button>
            </div>
            <div>
              {/* <div>
                <label htmlFor="storeName">Select Store:-</label>
                <select
                  className="form-select my-3"
                  id="storeName"
                  aria-label="Default select example"
                  onChange={handleStoreChange}
                  value={selectedValue}
                >
                  <option value="Select Store" selected>
                    Select Store
                  </option>
                  <input type="text" />
                  {storeData.map((item) => {
                    return (
                      <option value={item.storename}>
                        {item.id}- {item.storename}
                      </option>
                    );
                  })}
                </select>
                
              </div>  */}

              <div>
                <label htmlFor="storeName">Select Store:-</label>
                <SearchableSelect
                  options={storeData.map((item) => item.storename)}
                  onSelect={handleStoreSelect}
                />
              </div>

              {/* <Select
                  id="storeName"
                  value={selectedStoreOption}
                  onChange={(option) => setSelectedStoreOption(option)}
                  options={storeData.map((item) => ({
                    value: item.storename,
                    label: `${item.id}- ${item.storename}`,
                  }))}
                  placeholder="Select Store"
                /> */}
              <div>
                <div class="input-group my-3 w-50">
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Store Manager Name"
                    aria-label="Username"
                    value={storeManagerInput}
                    aria-describedby="basic-addon1"
                    onChange={(e) => setstoreManagerInput(e.target.value)}
                  />
                </div>
                <div class="input-group my-3 w-50">
                  <input
                    type="text"
                    value={storeManagerPhoneInput}
                    class="form-control"
                    placeholder="Phone Number"
                    aria-label="phone"
                    aria-describedby="basic-addon1"
                    onChange={(e) => setstoreManagerPhoneInput(e.target.value)}
                  />
                </div>

                {/* <div class="input-group my-3 w-50">
                <input type="text" class="form-control" value={selectedStore?.storecode} readOnly/>
                </div>
                  <label htmlFor="Address">Address:-</label>
                <div class="input-group my-3 w-75">
                <input type="text" class="form-control" id="Address" value={selectedStore?.Address}  readOnly/>
                </div> */}

                {selectedStore !== null ? (
                  <div class="card text-left my-3">
                    {/* <img class="card-img-top" src="holder.js/100px180/" alt=""> */}
                    <div class="card-body">
                      <h4 class="">Store Name: {selectedStore?.storename}</h4>
                      <h5 class="">Store Code: {selectedStore?.storecode}</h5>
                      <p class="card-text">Address: {selectedStore?.Address}</p>
                    </div>
                  </div>
                ) : null}
              </div>

              <PDFComponent
                data={{ Product: combinedData, Store: sendStoredata }}
              />
            </div>
          </div>
        </>
      ) : null}
    </Layout>
  );
};
export default CreateCatalog;
