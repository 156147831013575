import React from "react";
import "./layout.css";

const Header = () => {
  return (
    <div className="px-5 d-flex justify-content-between align-items-center header">
        <img className="logoImage" height={40} src="https://blackberrys.com/cdn/shop/files/logo.png" alt="logo"/>
      <h1 className="h2 text-center border-bottom pb-2 mt-3 mb-0 d-none d-md-block">
        Blackberrys Catalog App
      </h1>
    </div>
  );
};

export default Header;
