import React from "react";
import ImageEditor from "../../components/ImageEditor";
import Layout from "../../components/layout";

const ImageEditorPage = () => {
  return (
    <Layout>
      <ImageEditor />
    </Layout>
  );
};

export default ImageEditorPage;
