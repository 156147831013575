import { useState, useEffect } from "react";
import AdminLayout from "../../components/adminLayout";
import "./Admin.css";
import axios from "axios";

const Files = () => {

  const [editPop, setEditPop] = useState(false);
  const [viewPop, setViewPop] = useState(false);

  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [imgId, setImgID] = useState(true);


  const [inputValue, setInputValue] = useState({
    topBanner: {
      url: ''
    },
    bottomBanner: {
      url: ''
    }
  });

  const inputHandler = (e) => {
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  }

  console.log("object", inputValue)
  const getUrlList = () => {
    try {
      fetch('https://blackberry-9ab311620e64.herokuapp.com/banner/list')
        .then((response) => response.json())
        .then((data) => {
          setData(data.data);
          setLoading(false);
        })
    } catch (error) {
      console.error('Error fetching data:', error);
      setLoading(false);
    }
  }

  const EditPopHndle = (id) => {
    setImgID(id)
    setEditPop(true);
  };


  const updateUrl = async (event) => {
    // event.preventDefault();
    const imgData = {
      topBanner: {
        title: "Header Image",
        url: inputValue?.topBanner
      },
      bottomBanner: {
        title: "Bottom Image",
        url: inputValue?.bottomBanner
      }
    };
    try {
      await axios({
        url: `https://blackberry-9ab311620e64.herokuapp.com/banner/update/${imgId}`,
        method: "PUT",
        data: imgData,
      });
      window.location.reload();
    } catch (error) {
      console.log("error", error);

    }
  }

  useEffect(() => {
    getUrlList()
  }, []);

  const ViewPopHndle = () => {
    setViewPop(true);
  };


  console.log("data", data)
  console.log("inputValue", inputValue)
  console.log("imgId", imgId)

  return (
    <AdminLayout>
      <h2 className="text-center mt-5">Files</h2>
      <div className="container row">
        <div className="col-6">
          <div>
            <h2 className="text-center">Top Banner</h2>
          </div>
          <div class="row my-3 BannerContent justify-content-between align-items-center flex-column px-5">
            <img className="topBannerImg col-12 px-0" src={data[0]?.topBanner?.url} alt="..." />
          </div>
        </div>
        <div className="col-6">
          <div>
            <h2 className="text-center">Top Banner</h2>
          </div>
          <div class="row my-3 BannerContent justify-content-between align-items-center flex-column px-5">
            <img className="topBannerImg col-12 px-0" src={data[0]?.bottomBanner?.url} alt="..." />
          </div>
        </div>
        <div className="col-12 mt-2 px-5 text-center">
          <button className="btn btn-outline-danger" onClick={(e) => EditPopHndle(data[0]?._id)}>Edit</button>
        </div>
        {editPop === true ? (
          <>
            <div className="createloaderOverlay"></div>
            <div className="EditBannerPop p-5">
              <div className="d-flex justify-content-end mt-2">
                <button className="btn btn-outline-secondary" onClick={(e) => setEditPop(false)}>
                  Close
                </button>
              </div>
              <div className="">
                <div class="mb-3 col-5">
                  <label htmlFor="topbanner">Top Banner :-</label>
                  <input id="topbanner" type="text" className="form-control" name="topBanner" placeholder="Top Banner" aria-label="topbanner"
                    value={inputValue?.topBanner?.url}
                    onChange={(e) => inputHandler(e)}
                    aria-describedby="basic-addon1" />
                </div>
              </div>
              <div className="">
                <div class="mb-3 col-5">
                  <label htmlFor="bottombanner">Bottom Banner :-</label>
                  <input id="bottombanner" type="text" className="form-control" name="bottomBanner" placeholder="Bottom Banner"
                    value={inputValue?.bottomBanner?.url}
                    onChange={(e) => inputHandler(e)}
                    aria-label="bottombanner"
                    aria-describedby="basic-addon1" />
                </div>
              </div>
              <div className="d-flex">
                <button type="submit" onClick={() => updateUrl()} className="btn btn-primary m-auto">Submit</button>
              </div>
            </div>
          </>
        ) : null}
        <div className="mt-5">
          <h2 className="h2 text-center">Uploaded Files</h2>
          <table class="table table-bordered">
            <thead>
              <tr className="text-center">
                <th>#</th>
                <th>Top Banner</th>
                <th>Bottom Banner</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <p>Loading...</p>
              ) : (
                data.map((item, index) => (
                  <tr key={index} className="text-center">
                    <td>{index + 1}</td>
                    <td>
                      <img className="Bannerthumbnail" src={item?.headerImage?.url} alt="..." />
                    </td>
                    <td>
                      <img className="Bannerthumbnail p-0" src={item?.footerBanner?.url} alt="..." />
                    </td>
                    {/* <td className="tex-center">{item?.footerBanner?.url}</td> */}
                    <td className="">
                      <button className="btn btn-primary m-1">Copy</button>
                      <button className="btn btn-primary m-1">View</button>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Files;
