import React,{useState} from "react";
import { Link } from "react-router-dom";
import Header from "./header";
import Footer from "./footer";
import "./layout.css";
import Hamburger from "./burger-menu-svgrepo-com.svg";
import Close from "./close-svgrepo-com.svg";

const AdminLayout = ({ children }) => {
  const logout = () => {
    localStorage.removeItem("adminData");
    window.location.href = "/";
  };

  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const toggleButtonVisibility = () => {
    setIsButtonVisible(!isButtonVisible);
  };
  return (
    <div className="layout">
      <Header />
      <div className="d-flex position-relative layoutBody">
        <div className="sidebarLayout">
        {isButtonVisible && (
          <div className="d-md-none sidebarContent">
                 <div className="sidebar">
            <div className="d-flex flex-column p-3 sideNav">
              <Link
                className="text-decoration-none link"
                to={"/admin/admindashboard"}
              >
                Dashboard
              </Link>
              {/* <Link className='text-decoration-none link' to={'/createcatalog'}>Create Catalog</Link> */}
              <Link
                className="text-decoration-none link"
                to={"/admin/createuser"}
              >
                Create User
              </Link>
              <Link
                className="text-decoration-none link"
                to={"/admin/viewstore"}
              >
                Stores
              </Link>
              <Link
                className="text-decoration-none link"
                to={"/admin/viewuser"}
              >
                Users
              </Link>
              <Link
                className="text-decoration-none link"
                to={"/admin/analytics"}
              >
                Analytics
              </Link>
              <Link
                className="text-decoration-none link"
                to={"/admin/files"}
              >
                Files
              </Link>
              <Link className="text-decoration-none link" onClick={logout}>
                Logout
              </Link>
            </div>
          </div>
          </div>
        )}
        <button className="hamburger d-md-none mx-2 my-1" onClick={toggleButtonVisibility}>
          {  isButtonVisible === true ?
          <img src={Close} />
          :<img src={Hamburger} />
          }
        </button>
        <div className="d-none d-md-block">
        <div className="sidebar">
            <div className="d-flex flex-column p-3 sideNav">
              <Link
                className="text-decoration-none link"
                to={"/admin/admindashboard"}
              >
                Dashboard
              </Link>
              {/* <Link className='text-decoration-none link' to={'/createcatalog'}>Create Catalog</Link> */}
              <Link
                className="text-decoration-none link"
                to={"/admin/createuser"}
              >
                Create User
              </Link>
              <Link
                className="text-decoration-none link"
                to={"/admin/viewstore"}
              >
                Stores
              </Link>
              <Link
                className="text-decoration-none link"
                to={"/admin/viewuser"}
              >
                Users
              </Link>
              <Link
                className="text-decoration-none link"
                to={"/admin/analytics"}
              >
                Analytics
              </Link>
              <Link
                className="text-decoration-none link"
                to={"/admin/files"}
              >
                Files
              </Link>
              <Link className="text-decoration-none link" onClick={logout}>
                Logout
              </Link>
            </div>
          </div>
          </div>


        </div>
        <div className="contentLayout AdmincontentLayout">
          <main role="main">{children}</main>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default AdminLayout;
