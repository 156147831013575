import React, { useState , useEffect} from "react";
import { useNavigate } from "react-router-dom";
import Loader from "../../loader.svg";
import "./home.css";

function Home() {
  const [adminPassword, setAdminPassword] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [password, setPassword] = useState("");
  const [email, setEmail] = useState("");
  const [passwordError, setpasswordError] = useState("");
  const [emailError, setemailError] = useState("");
  const [loginType, setLoginType] = useState("store");
  const [loginloading, setloginloading] = useState(false);
  const navigate = useNavigate();



  // Check if user data is available in local storage when the component loads
  // useEffect(() => {
  //   const userData = JSON.parse(localStorage.getItem("userData"));
  //   const adminData = JSON.parse(localStorage.getItem("adminData"));

  //   if (userData) {
  //     navigate("/dashboard");
  //   } else if (adminData) {
  //     navigate("/admin/admindashboard");
  //   }
  // }, [navigate]);

  const handleValidation = (event) => {
    let formIsValid = true;

    if (email == "") {
      formIsValid = false;
      setemailError("Email Not Valid");
      return false;
    } else {
      setemailError("");
      formIsValid = true;
    }

    if (password == "") {
      formIsValid = false;
      setpasswordError("Please enter password");
      return false;
    } else {
      setpasswordError("");
      formIsValid = true;
    }

    return formIsValid;
  };

  const loginAdmin = async (e) => {
    setloginloading(true);
    e.preventDefault();
    // if (handleValidation()) {
    let payload = { email: adminEmail, password: adminPassword };

    try {
      const response = await fetch(
        "https://blackberry-9ab311620e64.herokuapp.com/adminlogin",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const responseData = await response.json();
        console.log(responseData, "responswDataaaa");
        if (responseData?.userCheck) {
          console.log("Login successful");
          const userData = {
            userId: responseData?.userCheck?._id,
            userName: responseData?.userCheck?.name,
            userEmail: responseData?.userCheck?.email,
            authToken: responseData?.token,
          };
          localStorage.setItem("adminData", JSON.stringify(userData));
          window.location.href = "/admin/admindashboard";
          setloginloading(false);
        } else {
          setloginloading(false);
          alert("Authentication failed ");
        }
      } else {
        setloginloading(false);
        console("Authentication failed");
      }
    } catch (error) {
      console.error("An error occurred:", error);
      setloginloading(false);
    }
    // }
  };

  const loginStore = async (e) => {
    setloginloading(true);
    e.preventDefault();
    if (handleValidation()) {
      let payload = { email, password };
  
      try {
        const response = await fetch(
          "https://blackberry-9ab311620e64.herokuapp.com/user/storeuserlogin",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(payload),
          }
        );
  
        if (response.ok) {
          const responseData = await response.json();
          console.log(responseData, "responseData");
          if (
            responseData.userCheck &&
            responseData.userCheck.email === email
          ) {
            console.log("Login successful");
            const userData = {
              userId: responseData.userCheck._id,
              userName: responseData.userCheck.name,
              userEmail: responseData.userCheck.email,
              authToken: responseData.token,
            };
            localStorage.setItem("userData", JSON.stringify(userData));
            setloginloading(false);
            // Redirect to the dashboard here
            window.location.href = "/dashboard";
          } else {
            setloginloading(false);
            alert("Invalid credentials");
          }
        } else {
          setloginloading(false);
          console.log("Authentication failed");
        }
      } catch (error) {
        setloginloading(false);
        console.error("An error occurred:", error);
      }
    }
  };
  





  const HandleUser = (type) => {
    setLoginType(type);
  };

  return (
    <section className="login-sectionn login-steps step-one position-relative">
      {loginloading === true ? (
        <>
          <div className="loginOverlay"></div>
          <div className="loginloader">
            <img src={Loader} />
          </div>
        </>
      ) : null}

      {/* <button type="button" className="btn btn-outline-dark position-absolute top-0  mt-1 LoginAs" onClick={HandleUser}>Login as user</button> */}
      <div className="container">
        <div className="row formContainer">
          <div className="col-md-6 discount-banner ">
            <img
              src="https://blackberrys.com/cdn/shop/files/logo.png?v=1629974004"
              className="img-fluid"
            />
          </div>
          <div className="col-md-6 login-boxx d-flex flex-column justify-content-center align-items-center">
            <div className="d-flex w-100 gap-2 mb-4">
              <button
                type="button"
                className={`btn btn-outline-dark ${
                  loginType === "store" ? "active" : ""
                }`}
                onClick={() => HandleUser("store")}
              >
                Store
              </button>
              <button
                type="button"
                className={`btn btn-outline-dark ${
                  loginType === "admin" ? "active" : ""
                }`}
                onClick={() => HandleUser("admin")}
              >
                Admin
              </button>
            </div>

            {loginType === "admin" && (
              <div className="box w-100 ">
                <h4 className="mb-4"> ADMIN LOGIN</h4>
                <form onSubmit={loginAdmin}>
                  <div className="form-group d-flex flex-column gap-2 ">
                    <label htmlFor="email">Email address</label>
                    <input
                      type="email"
                      className="form-control "
                      id="email"
                      required
                      onChange={(event) => setAdminEmail(event.target.value)}
                      placeholder="Enter email"
                    />
                    <small id="emailHelp" className="form-text text-black mb-2">
                      {emailError}
                    </small>
                  </div>
                  <div className="form-group d-flex flex-column gap-2">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control "
                      id="password"
                      placeholder="Password"
                      onChange={(event) => setAdminPassword(event.target.value)}
                    />
                    <small
                      id="passworderror"
                      className="text-black form-text mt-2"
                    >
                      {passwordError}
                    </small>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <button className="btn btn-dark mt-3">Submit</button>
                    <p className="mt-3 pt-2">Forgot password ?</p>
                  </div>
                </form>
              </div>
            )}

            {loginType === "store" && (
              <div className="box w-100">
                <h4 className="mb-4">STORE LOGIN</h4>
                <form onSubmit={loginStore}>
                  <div className="form-group d-flex flex-column gap-2 ">
                    <label htmlFor="email">Email address</label>
                    <input
                      type="email"
                      className="form-control "
                      id="email"
                      required
                      onChange={(event) => setEmail(event.target.value)}
                      placeholder="Enter email"
                    />
                    <small id="emailHelp" className="form-text text-black mb-2">
                      {emailError}
                    </small>
                  </div>
                  <div className="form-group d-flex flex-column gap-2">
                    <label htmlFor="password">Password</label>
                    <input
                      type="password"
                      className="form-control "
                      id="password"
                      placeholder="Password"
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    <small
                      id="passworderror"
                      className="text-black form-text mt-2"
                    >
                      {passwordError}
                    </small>
                  </div>
                  <div className="d-flex justify-content-between align-items-center">
                    <button className="btn btn-dark mt-3">Submit</button>
                    <p className="mt-3 pt-2">Forgot password ?</p>
                  </div>
                </form>
              </div>
            )}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Home;
