import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import axios from "axios";
// import Loader from "../../../public/loader.svg"
import Loader from "../../loader.svg";
import "./Admin.css";
import AdminLayout from "../../components/adminLayout";

const Viewstore = () => {
  const [storeData, setStoreData] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    const config = {
      method: "get",
      url: "https://blackberry-9ab311620e64.herokuapp.com/getstores", // Replace with your API URL
    };

    axios
      .request(config)
      .then((response) => {
        console.log("response.data", response.data);
        setStoreData(response.data);
        setLoading(false);
        // You can update your component's state with the response data here if needed.
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <AdminLayout>
      <h1 className="text-center">Store List</h1>
      {loading === true ? (
        <>
          <div className="createloader">
            <img src={Loader} alt="loader" />
          </div>
          <div className="createloaderOverlay"></div>
        </>
      ) : (
        <div className="p-4 mt-0 mb-5 border rounded w-100 d-flex justify-content-center flex-column mx-auto">
          <table class="table  table-striped">
            <thead>
                <tr>
                    <th>#</th>
                    <th>Store Name</th>
                    <th>Store Code</th>
                    <th>Store Manager Name</th>
                    <th>Store Phone</th>
                    <th>Store Address</th>
                </tr>
            </thead>
            <tbody>
{storeData && storeData.map((item)=>{
    return(
        <tr>

        <td scope="row">{item.id}</td>
        <td scope="row">{item.storename}</td>
        <td scope="row">{item.storecode}</td>
        <td scope="row">{item.managername}</td>
        <td scope="row">{item.store_phone}</td>
        <td scope="row">{item.Address}</td>
                </tr>
    )
})}
         
            </tbody>
          </table>
        </div>
      )}
    </AdminLayout>
  );
};

export default Viewstore;
