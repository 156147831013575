import React, { useState,useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import AdminLayout from "../../components/adminLayout";
import Storepopup from "../../components/storepopup";
// import StoreDatePopup from "../../components/storeDatePopup";

function Analytics() {
  const [isPopupOpen, setPopupOpen] = useState(false);
  // const [pdfPopupOpen, setPdfPopupOpen] = useState(false);
  const [todayCount,setTodayCount] = useState("00");
  const [totalpdf,SetTotalPdf]= useState("00")

  const openPopup = () => {
    setPopupOpen(true);
  };

  const closePopup = () => {
    setPopupOpen(false);
  };

  useEffect(() => {
    const TodayCount = {
      method: "get",
      url: "https://blackberry-9ab311620e64.herokuapp.com/analytic/todaycount", 
    };

    axios
      .request(TodayCount)
      .then((response) => {
        setTodayCount(response.data.todayCount);
      })
      .catch((error) => {
        console.log(error);
      });
    const TotalPdfGenerate = {
      method: "get",
      url: "https://blackberry-9ab311620e64.herokuapp.com/analytic/count", 
    };

    axios
      .request(TotalPdfGenerate)
      .then((response) => {
       SetTotalPdf(response.data.data)
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);
 
  return (
    <AdminLayout>
      <>
        <div className="container position-relative">
          <div className="analyticSection">
            <div className="analyticOrder">
              <div className="analyticTotalOrder">
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/image/totalpdf.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText">
                  <h2 className="analyticTotalOrderHead">Total PDF Genrate</h2>
                  <h2 className="analyticTotalOrderSubhead">{totalpdf}</h2>
                </div>
              </div>
              <div className="analyticTotalOrder">
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/image/orderpdf.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText">
                  <h2 className="analyticTotalOrderHead">Today PDF Order</h2>
                  <h2 className="analyticTotalOrderSubhead">{todayCount}</h2>
                </div>
              </div>
              <div className="analyticTotalOrder cardShow" onClick={openPopup}>
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/image/viewpdf.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText ">
                  <h2 className="analyticTotalOrderHead">View PDF Genrate</h2>
                </div>
              </div>

              <Link
                to="/admin/storedatewise"
                className="text-decoration-none analyticTotalOrder cardShow text-dark"
              >
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/dashboardLogo/datepdf.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText ">
                  <h2 className="analyticTotalOrderHead">
                    Date Vise PDF Genrate
                  </h2>
                </div>
              </Link>
            </div>
          </div>
          {isPopupOpen === true ? (
            <>
              <div className="storeViewOverlay"></div>
              <div className="storeView">
                <Storepopup isOpen={isPopupOpen} onClose={closePopup} />
              </div>
            </>
          ) : null}
          {/* {pdfPopupOpen === true ? (
            <>
              <div className="storeViewOverlay"></div>
              <div className="storeView">
                <StoreDatePopup
                  isOpen={pdfPopupOpen}
                  onClosePdf={closePdfPopup}
                />
              </div>
            </>
          ) : null} */}
        </div>
      </>
    </AdminLayout>
  );
}

export default Analytics;
