import React, { useState, useEffect } from "react";
import Layout from "../../components/layout";
import axios from "axios";
// import Loader from "../../../public/loader.svg"
import Loader from "../../loader.svg";
import "./Admin.css";
import AdminLayout from "../../components/adminLayout";

const Createuser = () => {
  const [storeData, setStoreData] = useState("");
  const [selectedStore, setSelectedStore] = useState(null);
  const [storeManagerPhoneInput, setstoreManagerPhoneInput] = useState("");
  const [storeManagerInput, setstoreManagerInput] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [storeCodeInput, setstoreCodeInput] = useState("");
  const [storeAddressInput, setstoreAddressInput] = useState("");
  const [selectedValue, setSelectedValue] = useState("Select Store");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const config = {
      method: "get",
      url: "https://blackberry-9ab311620e64.herokuapp.com/getstores", // Replace with your API URL
    };

    axios
      .request(config)
      .then((response) => {
        // console.log("response.data", response.data);
        setStoreData(response.data);
        // You can update your component's state with the response data here if needed.
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  // };

  useEffect(() => {
    if (selectedValue === "Select Store") {
      setSelectedStore(null); // No store selected, set to null or an empty object
    } else {
      // Find the selected store from storeData
      const selectedStoreData = storeData.find(
        (item) => item.storename === selectedValue
      );
      setSelectedStore(selectedStoreData);
    }
    setstoreManagerPhoneInput(selectedStore?.store_phone);
    setstoreManagerInput(selectedStore?.managername);
    setstoreCodeInput(selectedStore?.storecode);
    setstoreAddressInput(selectedStore?.Address);
  }, [selectedValue, storeData, selectedStore]);

  const handleStoreChange = (event) => {
    const selectedValue = event.target.value;
    setSelectedValue(selectedValue);
  };

  const CreateUserHandle = () => {
    setLoading(true);
    console.log("CreateUserHandle click");

    let payload = {
      phone: storeManagerPhoneInput,
      name: storeManagerInput,
      email: email,
      password: password,
      storeCode: storeCodeInput,
      storeAddress: storeAddressInput,
      storeName: selectedStore.storename,
    };

    const url =
      "https://blackberry-9ab311620e64.herokuapp.com/user/storeusersingup";

    // Send the POST request
    axios
      .post(url, payload)
      .then((response) => {
        // Handle the successful response here
        setLoading(false);
        console.log("POST request successful:", response.data);
      })
      .catch((error) => {
        // Handle any errors that occur during the request
        console.error("Error making POST request:", error);
      });

    console.log("CreateUserHandle", payload);
  };

  return (
    <AdminLayout>
      <h1 className="text-center">Create Store User</h1>
      {loading === true ? (
        <>
          <div className="createloader">
            <img src={Loader} alt="loader" />
          </div>
          <div className="createloaderOverlay"></div>
        </>
      ) : (
        <div className="p-4 mt-0 mb-5 border rounded w-75 d-flex justify-content-center flex-column mx-auto">
          <div>
            <label htmlFor="storeName">Select Store:-</label>
            <select
              className="form-select my-3"
              id="storeName"
              aria-label="Default select example"
              autoFocus="true"
              onChange={handleStoreChange}
              value={selectedValue}
            >
              <option value="Select Store" selected>
                Select Store
              </option>
              {storeData &&
                storeData.map((item) => {
                  return (
                    <option value={item.storename}>
                      {item.id}- {item.storename}
                    </option>
                  );
                })}
            </select>
          </div>
          <div>
            <label htmlFor="StoreManagerName">Store Manager Name:-</label>
            <div class="input-group my-2 ">
              <input
                type="text"
                id="StoreManagerName"
                class="form-control"
                placeholder="Store Manager Name"
                aria-label="Username"
                value={storeManagerInput}
                onChange={(e) => setstoreManagerInput(e.target.value)}
              />
            </div>
            <div class="my-3">
              <label className="form-label" htmlFor="phoneNumber">
                Store Manager Phone :-
              </label>
              <input
                type="text"
                id="phoneNumber"
                value={storeManagerPhoneInput}
                class="form-control"
                placeholder="Phone Number"
                aria-label="phone"
                aria-describedby="basic-addon1"
                onChange={(e) => setstoreManagerPhoneInput(e.target.value)}
              />
            </div>

            <div class="my-3">
              <label className="form-label" htmlFor="storecode">
                Store Code:-
              </label>
              <input
                type="text"
                id="storecode"
                class="form-control"
                value={storeCodeInput}
                onChange={(e) => setstoreCodeInput(e.target.value)}
              />
            </div>
            <div class="my-3">
              <label className="form-label" htmlFor="Address">
                Store Address:-
              </label>
              <input
                type="text"
                class="form-control"
                id="Address"
                value={storeAddressInput}
                onChange={(e) => setstoreAddressInput(e.target.value)}
              />
            </div>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">
                Store Manager Email:-
              </label>
              <input
                type="email"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="emailHelp"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div id="emailHelp" class="form-text">
                store user will login this email.
              </div>
            </div>
            <div class="mb-3">
              <label for="exampleInputEmail1" class="form-label">
                Store Manager Password:-
              </label>
              <input
                type="text"
                class="form-control"
                id="exampleInputEmail1"
                aria-describedby="passlHelp"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <div id="passlHelp" class="form-text">
                store user will login this password.
              </div>
            </div>

            <div>
              <button
                type="submit"
                className="btn btn-primary"
                onClick={CreateUserHandle}
              >
                Create User
              </button>
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  );
};

export default Createuser;
