/* eslint-disable jsx-a11y/heading-has-content */
/* eslint-disable no-unused-vars */

import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Link,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import axios from "axios";

const PdfFooter = (storedata) => {
  const [data, setData] = useState();

  useEffect(() => {
    setData(storedata);
  }, [storedata]);

  const styles = StyleSheet.create({
  prductName: {
      color: "#000",
      fontSize: "9px",
      fontWeight:"light"
  },

  mybox: {
      position: "absolute",
      bottom: 40,
      left: 0,
      right: 0,
      textAlign: "center",
      fontSize: 9,
      borderBottom: "1px solid black",
  },
  footer: {
      display: "flex",
      flexDirection: "row",
      justifyContent: "space-between",
      alignItems: "top",
      position: "absolute",
      // bottom: 45,
      left: 0,
      right: 0,
      fontSize: 9,
  },
  firstFooter: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      width: '100%',
      height: '100%',
      display: "flex",
      textAlign: 'center',
      position: "absolute",
      bottom: 200,
      //   left: 0,
      //   right: 0,
      //   fontSize: 9,
      // marginLeft: 20,
      // marginRight: 20,
      // justifyContent: "space-between",
      // alignItems: "top",
  },
  boldText: {
      fontWeight: 'bold',
      fontSize:"10px",
      color:"#000"
  },
  secondFooter: {
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      backgroundColor: 'white',
      width: '100%',
      height: '100%',
      display: "flex",
      textAlign: 'center',
      position: "absolute",
      bottom: 120,
      //   left: 0,
      //   right: 0,
      //   fontSize: 9,
      // marginLeft: 20,
      // marginRight: 20,
      // justifyContent: "space-between",
      // alignItems: "top",
  },
  });

  return (
    <>
      {/* <View style={styles.footer}>
        <Text style={styles.mybox}></Text>
        <View style={styles.firstFooter}>
          <Text style={styles.prductName}>STORE NAME :{data?.store?.storename}</Text>
          <Text style={styles.prductName}>STORE MANAGER :{data?.store?.managername}</Text>
        </View>
        <View style={styles.secondFooter}>
          <Text style={styles.prductName}>STORE ADDRESS :{data?.store?.storeAdress}</Text>
          <Text style={styles.prductName}>STORE  :{data?.store?.storePhone}</Text>
        </View>
      </View> */}
         <View style={""}>
                <View style={styles.firstFooter}>
                    <Text style={styles.prductName}>Explore your nearest Blackberrys store at <Text style={styles.boldText}> {data?.store?.storename} </Text>
                        {'\n'}
                        {'\n'}
                        for a first-hand look at our newest arrivals. Find your perfect fit with me,
                        {'\n'}
                        {'\n'}
                        <Text style={styles.boldText}> {data?.store?.managername}, your Fit Expert.</Text>
                        {'\n'}
                    </Text>
                </View>
                <View style={styles.secondFooter}>
                    <Text style={styles.prductName}>– We're here to assist you in making your shopping experience extraordinary!
                        {'\n'}
                        {'\n'}
                        Get in touch with me at<Text style={styles.boldText}> - +91 {data?.store?.storePhone}</Text>
                    </Text>
                </View>

            </View>
    </>
  );
};

export default PdfFooter;
