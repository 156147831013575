
import React, { useEffect, useState } from "react";
import {
    Document, Page, Text, View,
    StyleSheet,
    PDFViewer,
    Image,
    Link,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import logo from "./images/Logo.png"
import { saveAs } from "file-saver";
import axios from "axios";

const pdfHeader = () => {

    const styles = StyleSheet.create({
        Topbarsection: {
            margin: 10,
            padding: 10,
            justifyContent: "flex-end",
            flexDirection: "row",
            height: 40,
            position: "absolute",
            top: 5,
            alignItems: 'flex-end',
            backgroundColor: 'white',
            zIndex:999
        },
        logo: {
            // zIndex:999,
            height: 30,
            alignItems: 'flex-end',
        }
    });


    return (
        <div>
            <View style={styles.Topbarsection}>
                <Image src={logo} style={styles.logo} />
                {/* <Image
                    src="https://blackberrys.com/cdn/shop/files/logo.png?v=1629974004"
                    style={styles.logo}
                /> */}
            </View>
        </div>
    )
}

export default pdfHeader
