// import React, { useState, useEffect } from "react";
// import AdminLayout from "../../components/adminLayout";
// import Advanceloader from "../../advanceloader.gif";
// import "./Admin.css";
// import axios from "axios";
// import { apiurl } from "../../config";

// const SyncProducts = () => {
//   const [loading, setLoading] = useState(false);
//   const [message, setmessage] = useState('');
//   const [resultStatus, setresultStatus] = useState(false);

//   const syncProductTarget = () => {
//     console.log("click");
//     setLoading(true);
//     let config = {
//       method: "get",
//       maxBodyLength: Infinity,
//       url: `${apiurl}/sync`,
//       headers: {},
//     };
//     axios
//       .request(config)
//       .then((response) => {
//         console.log(JSON.stringify(response.data));
//         if(response?.data?.success === true) {
//             setLoading(true);
//             setresultStatus(true);
//             setmessage("Products Sync Successfully");
//         }
//       })
//       .catch((error) => {
//         console.log(error);
//         setresultStatus(false);
//         setLoading(true);
//         setmessage("Getting Error");
//       });
//   };

//   return (
//     <AdminLayout>
//       {loading ? (
//         <>
//           <div className="SyncProductsOverLay"></div>
//           <div className="d-flex flex-column align-items-center justify-content-center ">
//             <img className="w-25 mb-4" src={Advanceloader} alt="Loading" />
//             <div className="text-center">
//               <p className="taxt-danger">Its take Times to Syncing</p>
//               <h2 className="mb-3">Syncing Products</h2>
//               <h4 className="mb-3">Please wait</h4>
//               <p className="mb-3">while products are being synced.</p>
//               <p>Do not close or cancel this process.</p>
//             </div>
//           </div>
//         </>
//       ) : (
//         <>
//         {resultStatus === false?
//         <div className="d-flex justify-content-center align-items-center my-5">
//           <button
//             className="btn btn-outline-success m-auto"
//             onClick={syncProductTarget}
//           >
//             Sync Products
//           </button>
//         </div>
//           :
//         <div className="d-flex flex-column align-items-center justify-content-center ">
//           <img src="/success-green-check-mark-icon.png" alt="" />
//           <h2 className="mb-3">Syncing Products</h2>
//           </div>}</>
//       )}
//     </AdminLayout>
//   );
// };

// export default SyncProducts;

import React, { useState } from "react";
import AdminLayout from "../../components/adminLayout";
import Advanceloader from "../../advanceloader.gif";
import axios from "axios";
import { apiurl } from "../../config";

const SyncProducts = () => {
  const [loading, setLoading] = useState(false);
  const [syncStatus, setSyncStatus] = useState(null);

  const syncProductTarget = () => {
    setLoading(true);
    axios
      .get(`${apiurl}/sync`)
      .then((response) => {
        if (response.data.success === true) {
          setSyncStatus("success");
        } else {
          setSyncStatus("error");
        }
      })
      .catch((error) => {
        console.error("Error syncing products:", error);
        setSyncStatus("error");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <AdminLayout>
      {loading ? (
        <>
          <div className="SyncProductsOverLay"></div>

          <div className="d-flex flex-column align-items-center justify-content-center ">
            <img className="w-25 mb-4" src={Advanceloader} alt="Loading" />
            <div className="text-center">
              <p className="taxt-danger">Its take Times to Syncing</p>
              <h2 className="mb-3">Syncing Products</h2>
              <h4 className="mb-3">Please wait</h4>
              <p className="mb-3">while products are being synced.</p>
              <p>Do not close or cancel this process.</p>
            </div>
          </div>
        </>
      ) : (
        <>
          {syncStatus === null ? (
            <>
              <div className="d-flex justify-content-center align-items-center my-5">
                <button
                  className="btn btn-outline-success m-auto"
                  onClick={syncProductTarget}
                >
                  Sync Products
                </button>
              </div>
              <div className="d-flex m-auto justify-content-center">
                <p>
                  <span className="font-weight-bold">Note:-</span> Please check
                  before syncing: You have good internet.
                </p>
              </div>
            </>
          ) : (
            <div className="d-flex flex-column align-items-center justify-content-center ">
              {syncStatus === "success" ? (
                <>
                  <img src="/success-green-check-mark-icon.png" alt="Success" />
                  <h2>Syncing Products</h2>
                  <p>Products synced successfully!</p>
                </>
              ) : (
                <>
                  <img src="/round-information.png" alt="Error" />
                  <h2>Syncing Products</h2>
                  <p>Error syncing products. Please try again.</p>
                </>
              )}
              <button
                className="btn btn-outline-success m-auto"
                onClick={syncProductTarget}
              >
                Sync Again
              </button>
            </div>
          )}
        </>
      )}
    </AdminLayout>
  );
};

export default SyncProducts;
