import React from "react";
import Layout from "../../components/layout";
import { Link } from "react-router-dom";
import AdminLayout from "../../components/adminLayout";

const Admindashboard = () => {
  return (
    <AdminLayout>
      <div className="container ">
        <div className="row mt-5 m-1 m-md-5 d-flex justify-content-between">
          <Link
            to={"/createcatalog"}
            className="text-decoration-none analyticTotalOrder cardShow text-dark">
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/dashboardLogo/catlog.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText">
                  <h2 className="analyticTotalOrderHead">Create Catalog</h2>
                </div>
          </Link>
          <Link
            to={"/admin/createuser"}
            className="text-decoration-none analyticTotalOrder cardShow text-dark">
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/dashboardLogo/storeuser.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText">
                  <h2 className="analyticTotalOrderHead">Create Store User</h2>
                </div>
          </Link>
          <Link
            to={"/admin/viewstore"}
            className="text-decoration-none analyticTotalOrder cardShow text-dark">
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/dashboardLogo/viewstore.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText">
                  <h2 className="analyticTotalOrderHead">View Store</h2>
                </div>
          </Link>
        
          <Link
            to={"/admin/viewuser"}
            className="text-decoration-none analyticTotalOrder cardShow text-dark">
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/dashboardLogo/userlist.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText">
                  <h2 className="analyticTotalOrderHead">Store User List</h2>
                </div>
          </Link>
          <Link
            to={"/admin/analytics"}
            className="text-decoration-none analyticTotalOrder cardShow text-dark">
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/dashboardLogo/Analytics.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText">
                  <h2 className="analyticTotalOrderHead">Analytics</h2>
                </div>
          </Link>
          <Link
            to={"/admin/files"}
            className="text-decoration-none analyticTotalOrder cardShow text-dark">
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/dashboardLogo/files.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText">
                  <h2 className="analyticTotalOrderHead">Files</h2>
                </div>
          </Link>
          <Link
            to={"/admin/syncproducts"}
            className="text-decoration-none analyticTotalOrder cardShow text-dark">
                <div className="analyticTotalOrderImg">
                  <img
                    className="totalorderImage"
                    src="/dashboardLogo/files.png"
                    alt=""
                  />
                </div>
                <div className="analyticTotalOrderText">
                  <h2 className="analyticTotalOrderHead">Sync Products</h2>
                </div>
          </Link>
        </div>
      </div>
    </AdminLayout>
  );
};

export default Admindashboard;
