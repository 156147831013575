import React, { useState, useRef, useEffect } from "react";
import "./layout.css";

const ImageEditor = () => {
  const [contrast, setContrast] = useState(100); // Default contrast
  const [brightness, setBrightness] = useState(100); // Default brightness
  const [scale, setScale] = useState(1); // Default scale
  const [imageSrc, setImageSrc] = useState(null); // Uploaded image source
  const [frameSrc, setFrameSrc] = useState(null); // Frame image source
  const [imageWidth, setImageWidth] = useState(0); // Calculated image width
  const [imageHeight, setImageHeight] = useState(0); // Calculated image height
  const [selectedFrame, setSelectedFrame] = useState(null);
  const [canvasBorderSize, setCanvasBorderSize] = useState(0); // Default canvas border size
  const [canvasBorderColor, setCanvasBorderColor] = useState("black"); // Default canvas border color
  const [canvasWidth, setCanvasWidth] = useState(800); // Default canvas width
  const [canvasHeight, setCanvasHeight] = useState(600); // Default canvas height
  const [imageX, setImageX] = useState(0); // Image X position
  const [imageY, setImageY] = useState(0); // Image Y position
  const [canvasDragging, setCanvasDragging] = useState(false);
  const [startX, setStartX] = useState(0);
  const [startY, setStartY] = useState(0);
  const [text, setText] = useState(""); // Default text
  const [textColor, setTextColor] = useState("#000000"); // Default text color
  const [fontStyle, setFontStyle] = useState("Arial"); // Default font style
  const [fontSize, setFontSize] = useState(24); // Default font size
  const [textX, setTextX] = useState(20); // Text X position
  const [textY, setTextY] = useState(20); // Text Y position
  const [textDragging, setTextDragging] = useState(false);
  const [textStartX, setTextStartX] = useState(0);
  const [textStartY, setTextStartY] = useState(0);
  const [viewFrames, setviewFrames] = useState(false);

  const canvasRef = useRef(null);
  const frameRef = useRef(null);
  const textRef = useRef(null);

  // const frameOptions = [
  //   { name: "Frame 1", url: "/frames/frame.png" }, // Replace with actual paths
  //   { name: "Frame 2", url: "/frames/frame2.png" },
  //   { name: "Frame 3", url: "/frames/frame3.png" },
  //   { name: "Frame 4", url: "/frames/frame4.jpg" },
  //   { name: "Frame 5", url: "/frames/frame5.png" },
  //   { name: "Frame 6", url: "/frames/frame6.png" },
  //   // Add more frame options as needed
  // ];

  const frameOptions = [
    { name: "Frame 1", url: "/frames/frame.png", category: "birthday" },
    { name: "Frame 2", url: "/frames/frame2.png", category: "anniversary" },
    { name: "Frame 3", url: "/frames/frame3.png", category: "birthday" },
    { name: "Frame 4", url: "/frames/frame4.jpg", category: "anniversary" },
    { name: "Frame 5", url: "/frames/frame5.png", category: "birthday" },
    { name: "Frame 6", url: "/frames/frame6.png", category: "all" },
    // Add more frame options with categories as needed
  ];

  const fontStyles = [
    "Arial",
    "Verdana",
    "Times New Roman",
    "Georgia", // Add more font styles here
  ];

  useEffect(() => {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");

    if (imageSrc) {
      const img = new Image();
      img.src = imageSrc;

      img.onload = () => {
        // Determine canvas dimensions based on the fixed canvas size
        canvas.width = canvasWidth;
        canvas.height = canvasHeight;

        ctx.clearRect(0, 0, canvas.width, canvas.height);

        // Calculate image dimensions and position for scaling and cropping
        const scaledWidth = img.width * scale;
        const scaledHeight = img.height * scale;

        let sourceX = 0;
        let sourceY = 0;
        let sourceWidth = img.width;
        let sourceHeight = img.height;

        // Determine cropping dimensions based on the scaled image
        if (scaledWidth < canvas.width) {
          sourceX = (img.width - canvas.width / scale) / 2;
          sourceWidth = canvas.width / scale;
        }

        if (scaledHeight < canvas.height) {
          sourceY = (img.height - canvas.height / scale) / 2;
          sourceHeight = canvas.height / scale;
        }

        // Apply brightness and contrast adjustments
        ctx.filter = `contrast(${contrast}%) brightness(${brightness}%)`;

        // Draw the image within the canvas dimensions
        ctx.drawImage(
          img,
          sourceX,
          sourceY,
          sourceWidth,
          sourceHeight,
          imageX,
          imageY,
          scaledWidth,
          scaledHeight
        );

        // Draw the frame (if available)
        if (frameSrc) {
          const frameImage = new Image();
          frameImage.src = frameSrc;
          ctx.drawImage(frameImage, 0, 0, canvas.width, canvas.height);
        }

        // Draw canvas border
        ctx.lineWidth = canvasBorderSize;
        ctx.strokeStyle = canvasBorderColor;
        ctx.strokeRect(0, 0, canvas.width, canvas.height);

        // Draw text overlay
        ctx.font = `${fontSize}px ${fontStyle}`;
        ctx.fillStyle = textColor;
        ctx.fillText(text, textX, textY);

        const newImageWidth = scaledWidth;
        const newImageHeight = scaledHeight;
        setImageWidth(newImageWidth);
        setImageHeight(newImageHeight);
      };
    }
  }, [
    contrast,
    brightness,
    scale,
    frameSrc,
    imageSrc,
    canvasBorderSize,
    canvasBorderColor,
    canvasWidth,
    canvasHeight,
    imageX,
    imageY,
    text,
    textColor,
    fontStyle,
    fontSize,
    textX,
    textY,
  ]);

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onload = (e) => {
        setImageSrc(e.target.result);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleContrastChange = (e) => {
    setContrast(e.target.value);
  };

  const handleBrightnessChange = (e) => {
    setBrightness(e.target.value);
  };

  const handleScaleChange = (e) => {
    setScale(e.target.value);
  };

  const handleDownload = () => {
    const canvas = canvasRef.current;
    const downloadLink = document.createElement("a");
    downloadLink.href = canvas.toDataURL("image/jpeg");
    downloadLink.download = "edited_image.jpeg";
    downloadLink.click();
  };

  const handleFrameChange = (e) => {
    const selectedFrameUrl = e.target.value;
    setSelectedFrame(selectedFrameUrl);
    setFrameSrc(selectedFrameUrl);
  };

  const handleCanvasWidthChange = (e) => {
    setCanvasWidth(Number(e.target.value));
  };

  const handleCanvasHeightChange = (e) => {
    setCanvasHeight(Number(e.target.value));
  };

  const handleCanvasBorderSizeChange = (e) => {
    setCanvasBorderSize(Number(e.target.value));
  };

  const handleCanvasBorderColorChange = (e) => {
    setCanvasBorderColor(e.target.value);
  };

  const handleTextChange = (e) => {
    setText(e.target.value);
  };

  const handleTextColorChange = (e) => {
    setTextColor(e.target.value);
  };

  const handleFontStyleChange = (e) => {
    setFontStyle(e.target.value);
  };

  const handleFontSizeChange = (e) => {
    setFontSize(Number(e.target.value));
  };

  const handleCanvasMouseDown = (e) => {
    const mouseX = e.nativeEvent.offsetX;
    const mouseY = e.nativeEvent.offsetY;

    if (
      mouseX >= textX &&
      mouseX <= textX + textRef.current.width &&
      mouseY >= textY - fontSize &&
      mouseY <= textY
    ) {
      setTextDragging(true);
      setTextStartX(mouseX - textX);
      setTextStartY(mouseY - textY);
    } else {
      setCanvasDragging(true);
      setStartX(mouseX);
      setStartY(mouseY);
    }
  };

  const handleCanvasMouseMove = (e) => {
    const mouseX = e.nativeEvent.offsetX;
    const mouseY = e.nativeEvent.offsetY;

    if (canvasDragging) {
      setImageX(imageX + mouseX - startX);
      setImageY(imageY + mouseY - startY);
      setStartX(mouseX);
      setStartY(mouseY);
    }

    if (textDragging) {
      setTextX(mouseX - textStartX);
      setTextY(mouseY - textStartY);
    }
  };

  const handleCanvasMouseUp = () => {
    setCanvasDragging(false);
    setTextDragging(false);
  };

  // console.log("imageSrc", imageSrc);

  const [selectedCategory, setSelectedCategory] = useState("all");

  const handleCategoryChange = (event) => {
    setSelectedCategory(event.target.value);
  };

  return (
    <div className="container">
      <div className="row">
        <div
          className="col-3 position-relative"
          style={{ "border-right": "1px solid #ced4da" }}
        >
          {/* <label htmlFor="Contrast">Contrast:</label>
          <div className="d-flex justify-content-start align-content-center">
            <input
              type="range"
              min="0"
              max="200"
              id="Contrast"
              value={contrast}
              onChange={handleContrastChange}
            />
          </div>

          <label htmlFor="Brightness">Brightness:</label>
          <div className="d-flex justify-content-start align-content-center">
            <input
              type="range"
              min="0"
              id="Brightness"
              max="200"
              value={brightness}
              onChange={handleBrightnessChange}
            />
          </div>
          <label htmlFor="Scale">Scale:</label>
          <div className="d-flex justify-content-start align-content-center">
            <input
              type="range"
              min="0.1"
              max="2"
              step="0.01"
              id="Scale"
              value={scale}
              onChange={handleScaleChange}
            />
          </div> */}

          <div class="input-group my-3">
            <label>
              Canvas Width (px):
              <input
                type="number"
                className="form-control"
                value={canvasWidth}
                onChange={handleCanvasWidthChange}
              />
            </label>
          </div>
          <div class="input-group my-3">
            <label>
              Canvas Height (px):
              <input
                type="number"
                className="form-control"
                value={canvasHeight}
                onChange={handleCanvasHeightChange}
              />
            </label>
          </div>
          <div class="input-group my-3">
            <label>
              Canvas Border Size (px):
              <input
                type="number"
                className="form-control"
                value={canvasBorderSize}
                onChange={handleCanvasBorderSizeChange}
              />
            </label>
          </div>
          <div class="input-group my-3">
            <label>
              Canvas Border Color:
              <input
                type="color"
                className="form-control mt-2"
                value={canvasBorderColor}
                onChange={handleCanvasBorderColorChange}
              />
            </label>
          </div>
          <div class="input-group my-3">
            <label>
              Text:
              <input
                type="text"
                className="form-control"
                value={text}
                onChange={handleTextChange}
              />
            </label>
          </div>
          <div class="input-group my-3">
            <label>
              Text Color:
              <input
                type="color"
                className="form-control mt-2"
                value={textColor}
                onChange={handleTextColorChange}
              />
            </label>
          </div>
          <div class="input-group my-3">
            <label>Font Style:</label>
            <select
              className="custom-select form-control w-100"
              value={fontStyle}
              onChange={handleFontStyleChange}
            >
              {fontStyles.map((style) => (
                <option key={style} value={style}>
                  {style}
                </option>
              ))}
            </select>
          </div>
          <div class="input-group my-3">
            <label>
              Font Size (px):
              <input
                type="number"
                className="form-control"
                value={fontSize}
                onChange={handleFontSizeChange}
              />
            </label>
          </div>
          <div className="w-10">
            <button
              className="my-2 btn w-100 btn-outline-primary"
              onClick={(e) => setviewFrames(true)}
            >
              Select Frames
            </button>
          </div>
          {viewFrames === true ? (
            <div className="viewFrames shadow border p-2 rounded">
              <div className="d-flex justify-content-between mx-2">
                <p className="my-2">Select Frame:</p>
                <button
                  className="btn btn-outline-secondary"
                  onClick={() => setviewFrames(false)}
                >
                  x
                </button>
              </div>

              <div className="category-filter d-flex justify-content-around align-content-center my-2">
                <label>
                  <input
                    type="radio"
                    name="category"
                    value="all"
                    checked={selectedCategory === "all"}
                    onChange={handleCategoryChange}
                  />
                  All
                </label>
                <label>
                  <input
                    type="radio"
                    name="category"
                    value="birthday"
                    checked={selectedCategory === "birthday"}
                    onChange={handleCategoryChange}
                  />
                  Birthday
                </label>
                <label>
                  <input
                    type="radio"
                    name="category"
                    value="anniversary"
                    checked={selectedCategory === "anniversary"}
                    onChange={handleCategoryChange}
                  />
                  Anniversary
                </label>
              </div>
              <div className="row align-content-center justify-content-center">
                {/* {frameOptions.map((frame) => ( */}

                {frameOptions
                  .filter(
                    (frame) =>
                      selectedCategory === "all" ||
                      frame.category === selectedCategory
                  )
                  .map((frame) => (
                    <label
                      key={frame.url}
                      className={`framesOption p-2 m-1 col-5 ${
                        selectedFrame === frame.url ? "active" : ""
                      }`}
                    >
                      <input
                        type="radio"
                        name="frame"
                        className="d-none"
                        value={frame.url}
                        checked={selectedFrame === frame.url}
                        onChange={handleFrameChange}
                      />
                      {/* {frame.name} */}
                      <img src={frame?.url} width={100} height={100} alt="" />
                    </label>
                  ))}
              </div>
            </div>
          ) : null}

          {/* <div className="my-2 py-2 border-top">
            {imageSrc && (
              <div className="d-flex">
                <p>Image Dimensions:</p>
                <p>Width: {Math.floor(imageWidth)}px</p>
                <p>Height: {Math.floor(imageHeight)}px</p>
              </div>
            )}
            <button
              className="btn btn-outline-primary w-100 mt-3"
              onClick={handleDownload}
            >
              Download Edited Image
            </button>
          </div> */}
        </div>

        <div className="col-9 position-relative">
          {imageSrc === null ? (
            <div className="fileInput">
              <label
                htmlFor="file"
                className="d-flex justify-content-center align-items-center cursor-pointer fileInputLabel"
              >
                <p className="BrowseText">Browse or Drag and Drop .jpg, .png</p>
                <button className="BrowseFile">Browse File</button>
                <input
                  type="file"
                  id="file"
                  accept="image/*"
                  className="form-control d-none"
                  onChange={handleImageUpload}
                />
              </label>
            </div>
          ) : (
            <>
              <div className="d-flex justify-content-between align-content-center my-3">
                <div>
                  <label htmlFor="Contrast">Contrast:</label>
                  <div className="d-flex justify-content-start align-content-center">
                    <input
                      type="range"
                      min="0"
                      max="200"
                      id="Contrast"
                      value={contrast}
                      onChange={handleContrastChange}
                    />
                  </div>
                </div>

                <div>
                  <label htmlFor="Brightness">Brightness:</label>
                  <div className="d-flex justify-content-start align-content-center">
                    <input
                      type="range"
                      min="0"
                      id="Brightness"
                      max="200"
                      value={brightness}
                      onChange={handleBrightnessChange}
                    />
                  </div>
                </div>
                <div>
                  <label htmlFor="Scale">Scale:</label>
                  <div className="d-flex justify-content-start align-content-center">
                    <input
                      type="range"
                      min="0.1"
                      max="2"
                      step="0.01"
                      id="Scale"
                      value={scale}
                      onChange={handleScaleChange}
                    />
                  </div>
                </div>
                {imageSrc && (
                  <div className="row w-25">
                    <p className="m-0 caption col-12 text-center">
                      Image Dimensions:
                    </p>
                    <p className="m-0 caption col-6">
                      W: {Math.floor(imageWidth)}px
                    </p>
                    <p className="m-0 caption col-6">
                      H: {Math.floor(imageHeight)}px
                    </p>
                  </div>
                )}

                <button
                  className="btn btn-outline-primary mt-3"
                  onClick={handleDownload}
                >
                  Download Edited Image
                </button>
              </div>
            </>
          )}
          <canvas
            ref={canvasRef}
            onMouseDown={handleCanvasMouseDown}
            onMouseMove={handleCanvasMouseMove}
            onMouseUp={handleCanvasMouseUp}
            width={canvasWidth}
            height={canvasHeight}
            style={{
              border: `${canvasBorderSize}px solid ${canvasBorderColor}`,
            }}
          />
          <canvas
            ref={textRef}
            width={canvasWidth}
            height={canvasHeight}
            style={{
              position: "absolute",
              top: "0",
              left: "0",
              pointerEvents: "none",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default ImageEditor;