import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import CreateCatalog from "../pages/createCatalog";
import Home from "../pages/home";
import NotFoundPage from "../pages/404";
import Dashboard from "../pages/dashboard";
import AdminLogin from "../pages/admin";
import Createuser from "../pages/admin/createuser";
import Admindashboard from "../pages/admin/admindashboard";
import Userprofile from "../pages/userprofile";
import Viewuser from "../pages/admin/viewuser";
import Viewstore from "../pages/admin/viewstore";
import { useEffect, useState } from "react";
import CreateCatalog2 from "../pages/createCatlog2";
import Analytics from "../pages/admin/analytics";
import StoreDateWise from "../components/storeDateWise";
import Files from "../pages/admin/files.js";
import ImageEditorPage from "../pages/imageeditor";
import Syncproducts from "../pages/admin/syncproducts.js";

const Router = () => {
  const [userData, setUserData] = useState(null);
  const [adminData, setAdminData] = useState(null);

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem("userData");
    const adminDataFromStorage = localStorage.getItem("adminData");

    setUserData(userDataFromStorage ? JSON.parse(userDataFromStorage) : null);
    setAdminData(
      adminDataFromStorage ? JSON.parse(adminDataFromStorage) : null
    );
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/admin" element={<AdminLogin />} />

        {userData && ( 
        <>
          <Route path="/createcatalog" element={<CreateCatalog />} />
          <Route path="/" element={<CreateCatalog2 />} />
          <Route path="/userprofile" element={<Userprofile />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/imageeditor" element={<ImageEditorPage />} />
        </>
       )}

        {adminData && (
        <>
          <Route path="/admin/createuser" element={<Createuser />} />
          <Route path="/admin/viewuser" element={<Viewuser />} />
          <Route path="/admin/viewstore" element={<Viewstore />} />
          <Route path="/admin/analytics" element={<Analytics />} />
          <Route path="/admin/admindashboard" element={<Admindashboard />} />
          <Route path="/admin/storedatewise" element={<StoreDateWise />} />
          <Route path="/admin/files" element={<Files/>}/>
          <Route path="/admin/syncproducts" element={<Syncproducts/>}/>
        </>
       )} 
        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
    
  );
};

export default Router;
