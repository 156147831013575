import React, { useState } from "react";
import Sidebar from "./sidebar";
import Header from "./header";
import Footer from "./footer";
import "./layout.css";
import Hamburger from "./burger-menu-svgrepo-com.svg";
import Close from "./close-svgrepo-com.svg";

const Layout = ({ children }) => {
  const [isButtonVisible, setIsButtonVisible] = useState(false);
  const toggleButtonVisibility = () => {
    setIsButtonVisible(!isButtonVisible);
  };

  // const closeHandle = () => {
  //   setIsButtonVisible(false);
  // }

  return (
    <div className="layout">
      <Header />
      <div className="d-flex position-relative layoutBody">
        {isButtonVisible && (
          <div className="sidebarLayout d-md-none">
            <Sidebar />
            {/* <button className="" onClick={closeHandle}>X</button> */}
          </div>
        )}
        <button
          className="hamburger d-md-none mx-2 my-1"
          onClick={toggleButtonVisibility}
        >
          {isButtonVisible === true ? (
            <img src={Close} />
          ) : (
            <img src={Hamburger} />
          )}
        </button>
        <div className="sidebarLayout d-none d-md-block">
          <Sidebar />
        </div>
        <div className="contentLayout">
          <main role="main">{children}</main>
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Layout;
