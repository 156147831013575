/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Link,
} from "@react-pdf/renderer";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { saveAs } from "file-saver";
import axios from "axios";
import "./createCatalog.css";
import PdfHeader from "./pdfHeader";
import PdfFooter from "./pdfFooter";
import TopBanner from "./images/STORELOOKBOOKCOVER.jpg";
// import TopBanner from "./images/STORE_PDFF_1.jpg";
import TopSecBanner from "./images/STORE_PDFF_2.jpg";
import FooterBanner from "./images/STORE_PDFF_6.jpg";
import Indian_Rupee_symbol from "./Indian_Rupee_symbol.svg";

const styles = StyleSheet.create({
  page: {
    position: "relative",
    flexDirection: "col",
    backgroundColor: "#fff",
  },

  brandtext: {
    color: "#3d2d17",
    // fontFamily: 'Montserrat,sans-serif',
    fontWeight: 500,
    fontSize: "20px",
    height: "30px",
  },

  // Bannersection: {
  //   display: "flex",
  //   margin: 10,
  //   padding: 10,
  //   flexGrow: 1,
  //   flexDirection: "row",
  //   justifyContent: "space-between",
  //   alignItems: "top",
  //   width: "100%",
  // },

  banner: {
    // width: 100,
    // height: 100,
    // marginBottom: 8,
    // marginTop: 2,
  },

  section: {
    display: "flex",
    // margin: 10,
    padding: 10,
    flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "top",
    height: "20px",
  },
  newArrivalsText: {
    padding: 10,
    margin: 10,
    height: "40px",
    textAlign: "center",
    backgroundColor: "#FFFFFF",
  },
  productCardWraper: {
    display: "flex",
    margin: 10,
    padding: 10,
    // flexGrow: 1,
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "top",
    flexWrap: "wrap",
  },

  productImage: {
    height: "300",
  },
  productCard: {
    width: "48%",
    // height: 100,
    // margin: 8,
    margin: 5,
    height: "350px",
    border: "1px solid black",
    borderRadius: "5px",
    overflow: "hidden",
  },
  prductName: {
    color: "#222",
    fontWeight: 700,
    fontSize: "17px",
    margin: 4,
    paddingLeft: "10px",
  },
  button: {
    display: "flex",
    // margin: 10,
    // padding: 10,
    // flexGrow: 1,
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "top",
  },
  price: {
    width: "50%",
    height: 30,
    backgroundColor: "#ecebeb",
    textAlign: "center",
    color: "#000",
    paddingTopTop: 2,
    textDecorationStyle: "none",
  },
  link: {
    width: "50%",
    height: 30,
    backgroundColor: "#a2703b",
    textAlign: "center",
    color: "#fff",
  },
  footer: {
    position: "absolute",
    bottom: 20,
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 10,
  },
  parent: {
    marginLeft: 40,
    marginRight: 40,
    marginTop: "10%",
    height: "auto",
    // width:"100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftDiv: {
    marginRight: 15,
    width: "70%",
    height: "100%",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  imgStyle: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
    backgroundPosition: "center",
    maxWidth: "100%",
    maxHeight: "100%",
  },
  rightColomn: {
    marginLeft: 15,
    width: "30%",
    height: "100%",
  },
  upperDiv: {
    marginBottom: 5,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },
  lowerDiv: {
    marginTop: 5,
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
  },

  // Description & price
  Description: {
    marginLeft: 40,
    marginRight: 40,
    position: "relative",
    marginTop: "25%",
    flexDirection: "row",
    justifyContent: "center",
    alignItems: "center",
  },
  mybox: {
    bottom: 0,
    position: "absolute",
    left: 0,
    right: 0,
    textAlign: "center",
    fontSize: 9,
    color: "gray",
    borderBottom: "1px solid black"
  },
  lineHeading: {
    fontSize: "12px",
    fontStyle: "italic",
    fontWeight: 900,
  },
  HeadingDiv: {
    width: "100%",
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  borderDiv: {
    marginLeft: 10,
    width: "100%",
    // display: "flex",
  },
  priceMaped: {
    // lineHeight: 1,
    // padding: 5,
    fontSize: 20,
    font: "Arial",
    textAlign: "right",
  },
  pageImageStyle: {
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    // backgroundColor: 'white',
    width: '100%',
    height: '100%',
  },
  //   prductName: {
  //     color: "#000",
  //     fontSize: "9px",
  //     fontWeight: "light"
  // },

  firstFooter: {
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'center',
    backgroundColor: 'white',
    width: '100%',
    height: '100%',
    display: "flex",
    textAlign: 'left',
    position: 'absolute',
    bottom: 450,
    left: 10,
  },
  product: {
    fontWeight: 'bold',
    fontSize: "30px",
    marginLeft: "0px",
    paddingLeft: "0px",
    letterSpacing: "1px"
  },
  productDes: {
    fontWeight: 'bold',
    fontSize: "10px",
  },
  productPrice: {
    fontWeight: 'bold',
    fontSize: "15px",
  },
  linebreak: {
    top:60,
    // position: "absolute",
    left: 12,
    right: 0,
    // textAlign: "center",
    // fontSize: 9,
    borderBottom: "1px solid black",
    borderColor: 'black',
    width: '20%', 
    // marginTop: 10,
    // marginBottom: 10,
  }
});

const MyPDF = ({ jsonData }) => {
  const [Data, setData] = useState();
  // const [checkedItems, setCheckedItems] = useState([]);
  // const [SeletedItems, setSeletedItems] = useState([]);

  useEffect(() => {
    setData(jsonData);
  }, [jsonData]);
  // console.log("jsonData", Data);
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        {/* <PdfHeader /> */}
        <View style={styles.Bannersection}>
          <Image src={TopBanner} style={styles.pageImageStyle} />
        </View>
        <View style={styles.Bannersection}>
          <Image src={TopSecBanner} />
        </View>
        {/* <View style={styles.Bannersection}>
          <Image
            style={styles.Secbanner}
            source={
              "https://blackberrys.com/cdn/shop/files/lookbook3rd_banner_img1300px.jpg?v=1677052840"
            }
          />
        </View> */}
        {/* <Text style={styles.newArrivalsText}>New Arrivals Collection</Text> */}
        {/* <View style={styles.Bannersection}>
          <Image style={styles.Secbanner} source={'https://blackberrys.com/cdn/shop/articles/men-s-zipper-jacket-letting-you-show-off-a-bit-more-blackberrys-clothing_1024x1024.jpg?v=1685947396'} />
        </View> */}
        {/* <View style={styles.productCardWraper}>
          {Data === undefined ? (
            <Text>Data Not Available</Text>
          ) : (
            Data?.Product.map((item) => (
              <View style={styles.productCard}>
                <Image
                  style={styles.productImage}
                  src={
                    item?.image ??
                    "https://blackberrys.com/cdn/shop/files/logo_155x52.png"
                  }
                />
                <Text style={styles.prductName}>{item.title}</Text>
                <View style={styles.button}>
                  <Link style={styles.price}>{item.variants[0].price}</Link>
                  <Link
                    src={`https://theblackberrys.myshopify.com/products/${item.handle}`}
                    style={styles.link}
                  >
                    Quick Buy
                  </Link>
                </View>
              </View>
            ))
          )}
        </View> */}

        {/* <View style={styles.Bannersection}>
          <Image
            style={styles.Secbanner}
            source={
              "https://media.licdn.com/dms/image/D563DAQFiAIq9gsjFUA/image-scale_191_1128/0/1682674157730?e=1689498000&v=beta&t=vTNtCyNn-MoFdsgeDLTSiGCZZProWFn6GhKkXdKg4RQ"
            }
          />
        </View> */}
        {/* <View style={styles.Bannersection}>
          <Image style={styles.Secbanner} source={"https://cdn.shopify.com/s/files/1/0569/0284/4581/files/book_an_appointment.jpg?v=1677138473"} />
        </View> */}
        {/*  */}

        {/* <PdfFooter store={Data?.Store} /> */}
      </Page>

      {Data === undefined ? (
        <Text>Data Not Available</Text>
      ) : (
        Data?.Product.map((item) => {
          const compareAtPrice = item?.variants[0]?.compare_at_price;
          const price = item?.variants[0]?.price;

          const higherValue =
            compareAtPrice &&
            parseFloat(compareAtPrice) > parseFloat(price)
              ? compareAtPrice
              : price;
          return(
          <>
            <Page size="A4" style={styles.page}>
              {/*  */}
              <PdfHeader />
              <View>
                <View style={styles.Bannersection}>
                  <Image
                    style={styles.pageImageStyle}
                    src={item.images?.third}
                  />
                </View>
                <View className="">
                  <View style={styles.firstFooter}>
                    <Text style={styles.prductName}><Text style={styles.product}>{item?.title?.split('-').slice(1).join('-').trim()}</Text>
                      {'\n'}
                      <Text style={styles.productDes}>{item?.title?.split('-')[0].trim()}</Text>
                      {'\n'}
                      {'\n'}
                    <Text style={styles.productPrice}>Rs. {higherValue?.split('.')[0].trim()} /-</Text>
                    {/* <Text style={styles.productPrice}>Rs. {item?.variants[0]?.price?.split('.')[0].trim()} /-</Text> */}
                    </Text>
                    <Text style={styles.linebreak}></Text>
                  </View>
                </View>
                {/* <View style={styles.parent}>
                  <View style={styles.leftDiv}>
                    <Image
                      style={styles.imgStyle}
                      // source={
                      //   "https://cdn.shopify.com/s/files/1/0569/0284/4581/files/2-pcs-suit-in-beige-adera-blackberrys-clothing-1.jpg?v=1685953481"
                      // }
                      source={item.images?.first}
                    />
                  </View>
                  <View style={styles.rightColomn}>
                    <View style={styles.upperDiv}>
                      <Image
                        style={styles.imgStyle}
                        // source={
                        //   "https://cdn.shopify.com/s/files/1/0569/0284/4581/files/2-pcs-suit-in-beige-adera-blackberrys-clothing-2.jpg?v=1685953484"
                        // }
                        source={item.images?.second}
                      />
                    </View>
                    <View style={styles.lowerDiv}>
                      <Image
                        style={styles.imgStyle}
                        // source={
                        //   "https://cdn.shopify.com/s/files/1/0569/0284/4581/files/2-pcs-suit-in-beige-adera-blackberrys-clothing-6.jpg?v=1687856596"
                        // }
                        source={item.images?.third}
                      />
                    </View>
                  </View>
                </View> */}
                {/*  */}
                {/* <View style={styles.Description}>
                  <View style={styles.HeadingDiv}>
                    <Text style={styles.lineHeading}>{item.title} - {item?.variants[0]?.option1}</Text>
                    <Text style={styles.priceMaped}>Rs {item?.variants[0]?.price}</Text>
                  </View>
              
                  <Text style={styles.mybox}></Text>
                </View> */}
              </View>
              {/* <PdfFooter store={Data?.Store}/> */}
            </Page>
          </>
        )})
      )}
      {/* Footer Section */}
      <Page size="A4" style={styles.page}>
        {/* <PdfHeader /> */}
        {/* <View style={styles.Bannersection}>
          <Image style={styles.banner} src="https://blackberrys.com/cdn/shop/files/ABOUTUS-MAIN_IMAGE-jpeg__1.jpg?v=1632448576" />
        </View> */}
        <View style={styles.Bannersection}>
          <Image
            style={styles.pageImageStyle}
            src={FooterBanner}
          />
        </View>
        {/* <View style={styles.Bannersection}>
          <Image
            style={styles.Secbanner}
            source={
              "https://blackberrys.com/cdn/shop/files/lookbook3rd_banner_img1300px.jpg?v=1677052840"
            }
          />
        </View> */}

        {/* <View style={styles.Bannersection}>
          <Image
            style={styles.Secbanner}
            source={
              "https://blackberrys.com/cdn/shop/articles/men-s-zipper-jacket-letting-you-show-off-a-bit-more-blackberrys-clothing_1024x1024.jpg?v=1685947396"
            }
          />
        </View> */}
        <PdfFooter store={Data?.Store} />
      </Page>
    </Document>
  );
};

const downloadedData = async (data) => {
  // try {
  //   const { data } = await axios({
  //     url: `/orders/order_details`,
  //     method: "POST",
  //     // headers: {
  //     //   Authorization: ` ${token}`
  //     // },
  //     data: {
  //       order_id: data,
  //     },
  //   });

  // } catch (error) {
  // }
  const pdfData = {
    Product: data?.Product,
    storeName: data?.Store?.storename,
    managerName: data?.Store?.managername,
    storePhone: data?.Store?.storePhone,
    storeAddress: data?.Store?.storeAdress,
    storeCode: data?.Store?.StoreCode
  };
  axios({
    method: "post",
    url: "http://localhost:7000/analytic/create",
    payload: pdfData,
  })
    .then(function (response) {
      // handle success
      // console.log("success", response);
    })
    .catch(function (error) {
      console.log(error);
    });
};

const PDFComponent = ({ data }) => {
  const handleDownload = () => {
    const blob = new Blob([<MyPDF />], { type: "application/pdf" });
    saveAs(blob, "products.pdf");
  };
  // 
  const submitData = async (data) => {
    // debugger
    const pdfData = {
      Product: data?.Product,
      storeName: data?.Store?.storename,
      managerName: data?.Store?.managername,
      storePhone: data?.Store?.storePhone,
      storeAddress: data?.Store?.storeAdress,
      storeCode: data?.Store?.StoreCode
    };
    try {
      const { data } = await axios({
        url: `https://blackberry-9ab311620e64.herokuapp.com/analytic/create`,
        method: "POST",
        // headers: {
        //   Authorization: ` ${token}`
        // },
        data: pdfData,
      });
    } catch (error) {
      console.log("error", error);
    }
  }
  return (
    <div>
      {/* <PDFViewer width={600} height={800}>
        <MyPDF jsonData={data} />
      </PDFViewer> */}

      <PDFDownloadLink
        document={<MyPDF jsonData={data} />}
        fileName="products.pdf"
        onClick={() => submitData(data)}
        className="btn btn btn-primary"
      >
        {({ blob, url, loading, error }) =>
          loading ? "Download now!" : "Download now!"
        }
      </PDFDownloadLink>
      {/* <button onClick={handleDownload}>Download PDF</button> */}

      {/* <PDFDownloadLink document={<MyPDF />} fileName="example.pdf">
        {({ loading }) => (loading ? "Loading document..." : "Download PDF")}
      </PDFDownloadLink> */}
      {/* <button  onClick={handleDownload}>Download PDF</button> */}
    </div>
  );
};

export default PDFComponent;
