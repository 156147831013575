import { useState, useEffect } from "react";
// import "./files.css";
import Layout from "../../components/layout";
const Userprofile = () => {
  const [user, setUser] = useState();

  useEffect(() => {
    const storedUser = localStorage.getItem("userData");
    if (storedUser) {
      setUser(JSON.parse(storedUser));
    }
  }, []);

  // console.log(user, "userInfo");
  return (
    <Layout>
      <h2 className="text-center mt-5">User Profile Card</h2>
      <div className="card mt-4">
        {user && (
          <>
            <img
              className="w-25 mx-auto pt-2"
              src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQDy6RCP4XURksnhjmITnhHh3bA-m0thy_d3B-JDNM-KY1wNNnSQSL7ZIJxyvAAIKLTX4U&usqp=CAU"
            />
            <h1 className="mt-3">{user.userName}</h1>
            <p className="userEmail">{user.userEmail}</p>
            {/* <p>{user.university}</p> */}
          </>
        )}
      </div>
    </Layout>
  );
};

export default Userprofile;
