import React from "react";
import { Link } from "react-router-dom";
import "./layout.css";

const Sidebar = () => {
  const HandleLogout = () => {
    localStorage.removeItem("userData");
    window.location.href = "/";
  };

  return (
    <div className="sidebar sidebarContent">
      <div className="d-flex flex-column p-3 sideNav">
        <Link className="text-decoration-none link" to={"/dashboard"}>
          Dashboard
        </Link>
        <Link className="text-decoration-none link" to={"/createcatalog"}>
          Create Catalog
        </Link>
        {/* <Link className="text-decoration-none link" to={"/userprofile"}>
          Profile
        </Link> */}
        {/* <Link className="text-decoration-none link" to={"/imageeditor"}>
        Image Editor
        </Link> */}

        <Link className="text-decoration-none link" onClick={HandleLogout}>
          Logout
        </Link>
      </div>
    </div>
  );
};

export default Sidebar;
