import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="mb-3 pt-3 pt-md-5 border-top d-flex justify-content-center align-items-center">
      <p className="m-0">Created By:- </p>
      <a href='https://ens.enterprises'target="_blank"> ENS Enterprises Pvt Ltd</a>
    </div>
  );
};

export default Footer;
